export default {
  "common": {
    "password": "password",
    "password confirm": "password confirm",
    "email": "email",
    "mail address": "mail address",
    "language": "language",
    "next": "next",
    "pre": "pre",
    "name": "name",
    "name_kana": "name_kana",
    "name_kana_only": "name_kana_only",
    "role": "role",
    "action": "action",
    "status": "status",
    "active": "Hoạt động",
    "deactive": "Không hoạt động",
    "total": "total",
    "create": "create",
    "reset": "reset",
    "perpage": "perpage",
    "cancel": "cancel",
    "confirm": "confirm",
    "no": "no",
    "search": "search",
    "phone": "phone",
    "creator": "creator",
    "created at": "created at",
    "updated at": "updated at",
    "address": "address",
    "fax": "fax",
    "save": "save",
    "send": "send",
    "error": "An unexpected error has occurred.",
    "new_password": "new_password",
    "new_password_confirm": "new_password_confirm",
    "current_password": "current_password",
    "current_email": "current_email",
    "new_email": "new_email",
    "msg update ok": "Your registration changes have been completed successfully.",
    "msg create ok": "You have successfully registered",
    "msg delete ok": "Deleted successfully.",
    "delete confirm": "I will delete it. Is this ok?",
    "delete confirm title": "delete",
    "view": "view",
    "edit": "edit",
    "delete": "delete",
    "information of": "{entry}More information",
    "loading": "loading...",
    "list empty": "list empty",
    "start_date": "start date",
    "end_date": "end date",
    "price": "price",
    "upload submit": "upload submit",
    "choose file": "choose file",
    "file ext err": "Invalid file type. Please upload a csv type file.",
    "import success": "File upload successful.",
    "import err": "File upload failed.",
    "no_select": 'No data',
    "yes": "Yes",
    "no data found": "No data found!",
    "postal_code": 'post code',
    "note": 'note',
    "male": 'male',
    "female": 'female',
    "id": "ID",
    "generate": "generate",
    "all": "all",
    "choose": "--choose--",
    "date" : "date",
    "hour" : "hour",
    "mansion" : "mansion",
    "customer_name" : "customer name",
    "delete image title" : "",
    "pick_customer" : "pick customer",
    "recorded_by_name": "recorded by name",
    "updated_by_name": "updated by name",
    "facility" : "facility",
    "number_no" : "number no",
    "back" : "back",
    "cancel_booking" : "cancel booking",
    "select_customer" : "select customer",
    "download": "ダウンロード実行",
    "download_invoice_facility": "施設：利用料マスタ出力",
    "download_invoice_union": "組合：利用料マスタ出力",
    "download_invoice_detail_facility": "施設：利用料実績出力",
    "download_invoice_detail_union": "組合：利用料実績出力",
    "Upload execution": "アップロード実行",
    "select_prev_month": '"先月"を選択',
    "select_current_month": '"今月"を選択',
    "actions": "操作",
    "close": "閉じる",
    "msg duplicate email between input and exist data": "This email address is already in use.",
    "msg duplicate email between inputs": "The email address you entered is duplicated.",
    "unverify": "unverify",
    "name_last": "name last",
    "name_first" : "name first",
    "kana_last" : "kana last",
    "kana_first" : "kana first",
    "search_customer_name" : "search customer name",
    "index": "index",
  },
  "auth": {
    "login": "login",
    "login page": "login page",
    "forgot password page": "Reset password",
    "forgot password note": "A password reset URL will be sent to you via email.",
    "verify ok": "our registered email address has been confirmed.",
    "reset pass ok": "Password reset completed successfully.",
    "reset password page": "Reset password",
    "change password": "change password",
    "profile": "profile",
    "reset password": "reset password",
    "forgot_password_label": "Forgot your password?",
    'password note 1': "*Please include at least one number, one uppercase letter, and one lowercase letter.",
    'password note 2': "*Password must be between 8 and 20 characters long.",
    "url invalid" : "This URL is invalid. Please try again."
  },
  "dialog": {
    "confirm title": "Confirmation Dialog",
    "confirm body": "Are you sure do this action?"
  },
  "nav": {
    "dashboard": "dashboard",
    "logout": "logout",
    "user list": "user list",
    "admin account": "Quản lý nhân viên",
    "profile": "Profile",
    "users setting": "Users Setting",
    "Account Admin": "Danh sách quản lý",
    "Account Customer": "Danh sách KH",
    "Apartment List": "マンション一覧",
    "Master Booking Management": "予約マスタ管理",
    "Customer Booking List": "予約者一覧",
    "Booking Schedule": "予約スケジュール",
    "Member Management": "Quản lý khách hàng",
    "Member List": "Danh sách khách hàng",
    "Member Vital History": "会員の検温履歴",
    "Member Meal History": "会員の食事履歴",
    "Beacon Management": "ビーコン管理",
    "Gateway Management": "ゲートウェイ管理",
    "Facility Management": "館内施設管理",
    "Information": "インフォメーション",
    "Room Service": "ルームサービス",
    "Category Management": "Quản lý danh mục",
    "Menu Management": "Quản lý Menu",
    "Order List": "Danh sách Order",
    "Sale List": "売上一覧",
    "Receipt Management": "領収書管理",
    "QA Chatbot": "QAチャットボット",
    "Big Category List": "大カテゴリ一覧",
    "Medium Category List": "中カテゴリ一覧",
    "Small Category List": "小カテゴリ一覧",
    "Answer List": "カルーセル一覧",
    "Download CSV": "CSVダウンロード",
    "Receipts Invoice": "領収書 請求書",
    "Setting Notification Mail": "メール通知設定",
    "Customer Vital Management": "会員の検温履歴",
    "Upload CSV": "CSVアップロード",
    "Restaurant Management": "レストラン管理",
    "Announcement Management": "お知らせ",
    "AnnouncementMng Management": "お知らせ送信管理",
    "Transport Management": "送迎予約の一覧",
    "Invoice Receipt": "Invoice Receipt",
    "List of announcements": "お知らせ一覧",
    "Notification email settings": "お知らせメール設定",

    // Kyy
    "Bento Management": "Cơm hộp trường Nhật",
    "Menu Student": "Món ăn",
    "Student Orders": "Đơn hàng",
    "Export Order": "Xuất đơn hàng",
    "Sending Invoice": "Gửi hoá đơn",

    "Restaurant": "Nhà hàng",
    "Table Orders": "Đặt bàn online",

    "Menu Ship": "Đặt Menu Ship",
    "Category": "Danh mục Menu",
    "Category Menu": "Menu",
    "Standard Orders": "Đơn hàng ship",

    "News Management": "Tin tức",
    "News Category": "Danh mục tin tức",
    "News List": "Tin tức",

    "Settings": "Cài đặt",
    "Home Setting": "Cài đặt trang chủ",
    "Web Setting": "Cài đặt chung",
  },
  "breadcrumbs": {
    "Home": "ダッシュボード",
    "Dashboard": "ダッシュボード",
    "Users management": "Users",
    "Create user": "Create user",
    "Admin users": "Adminアカウント管理",
    "Profile": "プロファイル",
    "Users": "管理者アカウント",
    "Apartment": "Khang",
    "Apartment List": "マンション一覧",
    "Apartment Create": "マンション作成",
    "Apartment Update": "マンション編集",
    "Customer List": "会員一覧",
    "Customer Create": "会員作成",
    "Customer Update": "会員編集",
    "Account List": "Danh sách quản lý",
    "Account Create": "Thêm quản lý",
    "Account Edit": "Sửa quản lý",
    "Customer Vital List": "会員の検温履歴",
    "Customer Vital Create": "会員の検温履歴作成",
    "Customer Vital Update": "会員の検温履歴編集",
    "Customer Meals Create" : "会員の食事履歴作成",
    "Customer Meals List"  : "会員の食事履歴",
    "Customer Meals Edit"  : "会員の食事履歴編集",
    "Master Booking List"  : "予約マスタ一覧",
    "Master Booking Create"  : "予約マスタ作成",
    "Master Booking Update"  : "予約マスタ編集",
    "Facility Create" : "館内施設作成",
    "Facility Edit" : "館内施設編集",
    "Facility List" : "館内施設管理",
    "Facility Manage Status" : "混雑状況設定",
    "Information Create" : "インフォメーション作成",
    "Information List" : "インフォメーション管理",
    "Information Edit" : "インフォメーション編集",
    "Plan Create" : "館内施設プラン作成",
    "Plan List"   : "館内施設プラン管理",
    "Plan Edit"   : "館内施設プラン編集",
    "Reservation Create" : "予約情報作成",
    "Reservation List" : "予約者一覧",
    "Reservation Edit" : "予約情報詳細表示",
    "Reservation Block Create" : "予約情報作成（予約ブロック）",
    "Reservation Block Edit" : "予約情報詳細表示（予約ブロック）",
    "Booking Schedule List" : "予約スケジュール",
    "Beacons Create" : "ビーコン作成",
    "Beacons List" : "ビーコン管理",
    "Beacons Edit" : "ビーコン編集",
    "Beacons History" : "ビーコン履歴 ",
    "Big Category List": "大カテゴリ一覧",
    "Big Category Create": "大カテゴリ作成",
    "Big Category Edit": "大カテゴリ編集",
    "Gateway Create" : "ゲートウェイ作成",
    "Gateway List" : "ゲートウェイ管理",
    "Gateway Edit" : "ゲートウェイ編集",
    "Gateway History" : "ゲートウェイ履歴",
    "Medium Category List": "中カテゴリ一覧",
    "Medium Category Create": "中カテゴリ作成",
    "Medium Category Edit": "中カテゴリ編集",
    "Service Category Create" : "テイクアウト・ルームサービスのカテゴリ作成",
    "Service Category Edit" : "テイクアウト・ルームサービスのカテゴリ編集",
    "Service Category List" : "テイクアウト・ルームサービスのカテゴリ一覧",
    "Menu Create" : "テイクアウト・ルームサービスのメニュー作成",
    "Menu Edit" : "テイクアウト・ルームサービスのメニュー編集",
    "Menu List" : "テイクアウト・ルームサービスのメニュー一覧",
    "Small Category List": "小カテゴリ一覧",
    "Small Category Create": "小カテゴリ作成",
    "Small Category Edit": "小カテゴリ編集",
    "Restaurants Create" : "レストランテーブル作成",
    "Restaurants List" : "レストランテーブル管理",
    "Restaurants Edit" : "レストランテーブル編集",
    "Order List" : "テイクアウト・ルームサービスの注文一覧",
    "Order Create" : "テイクアウト・ルームサービスの注文作成",
    "Order Edit" : "テイクアウト・ルームサービスの注文編集",
    "Answer List": "カルーセル一覧",
    "Answer Create": "カルーセル作成",
    "Answer Edit": "カルーセル編集",
    "Sale List": "売上一覧",
    "Receipts List": "領収書管理",
    "Receipts Create": "領収書作成",
    "Receipts Edit": "領収書編集",
    "Download CSV": "CSV出力機能",
    "Notifies List": "お知らせメール設定 ",
    "Announcement List": "お知らせ一覧",
    "AnnouncementMng List": "お知らせ送信管理",
    "AnnouncementMng Create": "お知らせ作成",
    "AnnouncementMng Edit": "お知らせ編集",
    "AnnouncementMng Preview": "お知らせ",
    "Upload CSV": "CSVアップロード機能",
    "Transports List" : "送迎サービスの予約一覧",
    "Transports Create" : "送迎マスタ作成",
    "Transports Edit" : "送迎マスタ編集",
    "Sale Detail Apartment" : "売上詳細",
    "Sale Detail Customer" : "売上詳細",
    "Invoice Receipt" : "invoice receipt",

    // kyy khang
    "MasterRestaurantList": "Danh sách nhà hàng",
    "MasterRestaurantCreate": "Tạo nhà hàng mới",
    "MasterRestaurantUpdate": "Cập nhật thông tin nhà hàng",

    "MasterStudentOrderList": "Quản lý đặt cơm học sinh",
    "MasterStudentOrderDetail": "Chi tiết thông tin đặt cơm",

    "MasterExportOrder": "Xuất Excel đơn hàng",
    "MasterStudentInvoiceList": "Danh sách hoá đơn khách hàng",
    "MasterStudentInvoiceCreate": "Tạo hoá đơn khách hàng",

    "MasterMenuStudentList": "Quản lý thực đơn học sinh",
    "MasterMenuStudentCreate": "Tạo thực đơn mới",
    "MasterMenuStudentUpdate": "Cập nhật thông tin thực đơn",

    "MasterCategoryList": "Quản lý danh mục",
    "MasterCategoryCreate": "Tạo danh mục mới",
    "MasterCategoryUpdate": "Cập nhật thông tin danh mục",

    "MasterCategoryMenuList": "Quản lý thực đơn",
    "MasterCategoryMenuCreate": "Tạo thực đơn mới",
    "MasterCategoryMenuUpdate": "Cập nhật thông tin thực đơn",

    "MasterTableOrderList": "Quản lý đặt bàn",
    "MasterTableOrderCreate": "Tạo đặt bàn mới",
    "MasterTableOrderUpdate": "Cập nhật thông tin đặt bàn",

    "MasterStandardOrderList": "Quản lý order nhà hàng",

    "MasterNewsCategoryList": "Quản lý danh mục tin tức",
    "MasterNewsCategoryCreate": "Tạo danh mục tin tức",
    "MasterNewsCategoryUpdate": "Cập nhật danh mục tin tức",

    "MasterNewsList": "Quản lý tin tức",
    "MasterNewsCreate": "Tạo tin tức mới",
    "MasterNewsUpdate": "Cập nhật tin tức",

    "MasterCustomersList": "Quản lý khách hàng",
    "MasterCustomersCreate": "Tạo khách hàng mới",
    "MasterCustomersUpdate": "Cập nhật khách hàng",

    "MasterHomeSetting": "Cài đặt trang chủ",
    "MasterWebSetting": "Cài đặt chung",
  },
  "users": {
    "admin user list": "Adminアカウント管理",
    "create user": "アカウント登録",
    "update user": "アカウント編集",
    "resent_active_email": 'Resent Active URL',
    "sent_email_msg": "Sent Email Successful.",
    "role": "Authority",
    "admin": "Admin",
    "user": "User",
    "user list": "User List",
    "create_password_note": "The initial password is generated randomly.",
    "create_email_note": "Login information will be sent to the email address you registered.",
    "edit_note1": "If you change your email address, a confirmation email will be sent to the new email address.",
    "edit_note2": "*The email address change will be complete once you access the URL in the confirmation email.",
    "change_password": "Change your password here",
    "change_other_password": "Click here to change anything other than your password",
  },
  "book-masters": {
    "time": "時間",
    "work_days": "営業日",
    "sunday": "日",
    "monday": "月",
    "tuesday": "火",
    "wednesday": "水",
    "thursday": "木",
    "friday": "金",
    "saturday": "土",
    "unit_time": "予約の時間単位",
    "unit_time_list": "予約時間単位",
    "opening_time": "開始時間",
    "closing_time": "終了時間",
    "unit_capacity": "予約可能人数",
    "limit_booking_in_unit_time": "予約可能件数",
    "limit_booking_hours": "予約者の利用制限",
    "limit_booking_times": "時間あたり",
    "facility": "施設",
    "filter_facility": "館内施設",
  },
  "apartments": {
    "name": "名前",
    "person_in_charge": "担当者",
    "address": "住所",
    "prefecture": '都道府県',
    "postal_code": '郵便番号',
    "settings": '基本設定',
    "url": 'Web URL',
    "api_key": 'APIキー発行',
    "note": '備考',
    "line_setting": 'LINE設定',
    "veritrans_setting": '決済設定',
    "line_token" : "アクセストークン",
    "line_webhook_url" : "Webhook URL",
    "line_basic_id" : "ベーシックID",
    "payment_vt_token" : "アクセストークン",
    "payment_vt_ccid" : "CCID",
    "payment_vt_secretkey" : "秘密鍵",
    "id_en" : "ID",
    "name_en": "Name",
    "token_en": "Token",
    "edit_en": "Edit",
    "add_api_key": "項目追加",
    "update": "保存",
    "delete": "削除",
    "transport_service": "送迎サービス",
    "status_transport_service": "利用する",
    "pickup_dropoff": "送迎設定",
    "pickup_area": "送迎エリア",
    "name_shuttle_car": "送迎車の名称",
    "business_day": "営業日",
    "business_hour": "営業時間",
    "time_unit": "時間単位",
    "capacity": "定員",
    "operation": "操作",
    "add_pickup_droppff": "項目追加",
    "delete confirm transport": "削除します。よろしいでしょうか？",
    "zipcode_id": '郵便番号',
    "line_qrcode": 'LINE コード',
  },
  "transport": {
    "car_name": "名前（車名）",
    "interval_unit_count": "予約と予約の間隔",
    "delete confirm transport": "の送迎マスタを削除しますか？",
    "status_booking": "ステータス",
    "customer_name_booking": "予約者名",
    "customer_name_katakana": "フリガナ",
    'time_booking': "予約日時",
    "customer_phone": "電話番号",
    "customer_phone_cellular": "携帯電話",
    "transport_car_id": "送迎プラン",
    "address_pickup": "出発地",
    "address_dropoff": "行き先",
    "unit_capacity": "乗車定員"
  },
  "customers": {
    "status_enabled": "ステータス",
    "apartment": "マンション",
    "room": "部屋",
    "line_code": "LINEコード",
    "line_id": "LINE ID",
    "phone_cellular": "携帯番号",
    "fax": "FAX番号",
    "birthday": "生年月日",
    "blood_type": "血液型",
    "blood_rh": "RH",
    "setai": "世帯区分",
    "phone_fax": "性別",
    "user_number": "利用者番号",
    "gender": "性別",
    "user_number_confirm_title": "確認",
    "user_number_confirm_body": "利用者番号でほのぼの会計の情報を連携しますか？",
    "sync_data": "データ同期",
    "user_number_customer_not_found": "システムは顧客情報を見つけることができませんでした。",
    "change_apartment_confirm" : "マンションを変更すると、この会員はLINEコードを再度連携する必要があります。よろしいでしょうか？",
    "change_status_confirm" : "ステータスを変更すると、この会員はLINEコードを再度連携する必要があります。よろしいでしょうか？",
    "msg create ok": "スマレジで正常に登録できました。",
    "msg update ok": "スマレジで更新できました。"
  },
  "customer-vitals": {
    "datetime": "記録日時",
    "customer_name": "会員ID",
    "situation": "状況",
    "temperature": "体温",
    "pressure_high": "血圧（高）",
    "pressure_low": "血圧（低）",
    "pulse_rate": "脈拍",
    "respiratory_rate": "呼吸",
    "spo2": "SPO2",
    "irregular_pulse": "不整脈",
    "blood_sugar": "血糖値",
    "recorded_at": "記録日",
    "recorded_time_at": "時間",
    "btn_select_customer": "会員一覧から選択",
    "recorded_by_name": "記録作成者",
    "updated_by_name": "最終更新者",
    "name_kana_list_search": "名前・カナ",
  },
  "customer-meals": {
    "served_at": "摂取日時",
    "apartment": "マンション",
    "customer_member_id": "会員ID",
    "note": "備考",
    "pressure_high": "血圧（高）",
    "pressure_low": "血圧（低）",
    "pulse_rate": "呼吸",
    "respiratory_rate": "呼吸",
    "spo2": "SPO2",
    "irregular_pulse": "不整脈",
    "blood_sugar": "血糖値",
    "type_of_three_meals" : "食事の種類",
    "tab_meals" : "朝/昼/夕食",
    "tab_snack" : "間食・水分",
    "type_of_meals" : "食事の形態",
    "amount_of_main" : "摂取量(主食)",
    "amount_of_sub" : "摂取量(副食)",
    "amount_of_soup" : "摂取量(汁物)",
    "is_drink_liquid" : "水分も摂取しました",
    "name_of_drink" : "水分の名称",
    "amount_of_drink" : "摂取量",
    "type_of_meal_snack" : "食事の種類",
    "name_snack" : "食事の名称",
    "amount_snack" : "摂取量",
    'type_of_meal' : "食事の形態",
    "customer_name_kana" : "名前・カナ",
    "content" : "内容"
  },
  "pages": {
    "404_msg": "アクセスいただいたURLが見つかりません。",
    "url_invalid" : "このURLは無効です。再度手続きをしてください。"
  },
  "accounts": {
    "filter_apartment": "マンション",
    "confirm_send_mail_verify": " メールアドレスの認証が完了していません。認証URLを再送信しますか？",
  },
  "messages": {
    'postal_code_invalid': '有効な郵便番号を入力してください。',
    'account_deleted': '管理者アカウントのステータスを無効にしたため強制ログアウトされました。アカウントを再度有効にしたい場合は他の管理者にご確認ください'
  },
  "facilities" : {
    "serial_id" : "管理番号",
    "name" : "名前",
    "description" : "説明文",
    "biz_type" : "運営タイプ",
    "status_disp_crowed" : "混雑確認ステータス",
    "status_disp_guide" : "施設案内ステータス",
    "status_disp_signage" : "サイネージ表示",
    "order_disp_crowded" : "混雑確認表示順",
    "order_disp_guide" : "施設案内表示順",
    "web_url" : "Web URL",
    "image" : "写真",
    "browse" : "参照",
    "booking_flow_type" : "予約者の画面順序",
    "label_booking_flow_type" : "※予約スケジュールにて空き状況を確認することができます。",
    "gateway_disp_type" : "表示方式",
    "gateway_threshold_available_max" : "空いている状況の上限数",
    "gateway_threshold_crowded_max" : "やや混んでいる状況の上限数",
    "gateway_available_api_id" : "館内施設API ID",
    "gateway_available_gateway_serial" : "ゲートウェイ管理番号",
    "gateway_unit_time" : "混雑表示の時間間隔",
    "mansion_id" : "マンション",
    "detail_button_status" : "ボタン表示",
    "detail_content" : "編集画面",
    "html_preview" : "HTMLプレビュー",
    "global_setting" : "基本設定",
    "detail_setting" : "詳細説明",
    "filter_name" : "施設名",
    "filter_confirm_status_crowded" : "混雑確認",
    "filter_time_working" : "営業時間",
    "filter_status_crowded" : "混雑状況",
    "filter_update_status_crowded" : "混雑状況更新",
    "filter_sign_display" : "サイネージ表示",
    "filter_list_plan" : "プラン一覧",
    "filter_facility_id" : "館内施設",
    "back" : "戻る",
    "biz_type_err": "運営タイプが施設である館内施設だけ選択してください。"
  },
  "information" : {
    "status" : "ステータス",
    "mansion" : "マンション",
    "title" : "タイトル",
    "description" : "説明文",
    "display_order" : "表示順",
    "image_path" : "写真",
    "browsing" : "参照",
    "web_url" : "Web URL",
    "url_label" : "※インフォメーションの種類が「お知らせとして、指定されたURLの内容をインラインで表示する」の場合はURLの指定が必須です。",
    "information_type" : "記事の種類",
    "contents" : "コンテンツ編集",
    "keyword" : "キーワード検索",
    "date" : "日付",
    "created_at" : "作成日",
    "detail_content" : "コンテンツ編集",
    "filter_type" : "記事種別",
    "type_list" : "種別",
    "image" : "画像"
  },
  "plans" : {
    "mansion" : "マンション",
    "facility" : "館内施設",
    "status" : "ステータス",
    "name" : "名前",
    "web_url" : "Web URL",
    "plan_unit_minutes" : "プラン利用時間",
    "minutes" : "分単位",
    "note" : "備考",
    "price" : "価格（税込）",
    'id' : 'ID',
    "label_book_master" : " 最初に、作成する利用プランが属する予約マスタを選択してください。<br>" +
      "                選択した予約マスタに応じて、設定可能な利用時間が変わります。",
    "book_master" : "予約マスタ選択",
    "tax" : "消費税額",
    "is_reduced_tax" : "軽減税率",
    "text_is_reduced_tax" : "適用する",
    "price_without_tax" : "価格（税抜）",
    "min" : "分",
    "facilities" : "施設"
  },
  "reservation" : {
    "status" : "ステータス",
    "date" : "日付",
    "time" : "時間",
    "mansion" : "マンション",
    "facility" : "館内施設",
    "plan" : "プラン",
    "number_of_people" : "予約人数",
    "name" : "名前",
    'phone' : "電話番号",
    "note" : "備考",
    "customer_note" : "顧客コメント",
    "repeat_type" : "繰り返し設定",
    "repeat_custom_wdays" : "繰り返し期限",
    "start_time" : "開始時間",
    "close_time" : "終了時間",
    "created_at" : "作成日",
    "filter_customer_name" : "予約者名",
    "cancel_book_title" : "予約のキャンセル",
    "cancel_book_content" : "こちらの予約をキャンセルしてもよろしいですか？",
    "customer" : "顧客",
    "delete_book_content" : "こちらの予約ブロックをキャンセルしてもよろしいですか？",
    "check_unique_name_title" : "確認"
  },
  "beacons" : {
    "status" : "ステータス",
    "mansion" : "マンション",
    "name" : "名前",
    "beacon_id" : "ビーコンID",
    "serial" : "管理番号",
    "number_of_peoples" : "設定数",
    "created_at" : "作成日",
    "updated_at" : "更新日",
    "history" : "履歴",
    "date_get_data" : "データ取得日",
    "gateway" : "ゲートウェイ",
    "signal" : "信号強度",
    "report" : "レポート種類",
    "data_package" : "データパッケージ",
    "date" : "日付"
  },
  "big-category" : {
    "name" : "大カテゴリー名",
    "note" : "説明",
  },
  "gateway" : {
    "status" : "ステータス",
    "mansion" : "マンション",
    "facility" : "館内施設",
    "name" : "名前",
    "mac_id" : "Mac ID",
    "serial" : "管理番号",
    "signal" : "信号強度",
    "history": "履歴",
    "fetched_at" : "最新データ",
    "status_active" : "稼働状況",
    "number_beacon" : "ビーコン数",
    "history_fetched_at" : "データ取得日"
  },
  "medium-category" : {
    "name_big" : "大カテゴリ",
    "name" : "カテゴリ名",
    "note" : "説明"
  },
  "service_category" : {
    "name" : "名前",
    "note" : "備考",
    "category_name" : "カテゴリー名",
    "category_note" : "説明"
  },
  "menu" : {
    "name" : "名前",
    "status" : "ステータス",
    "mansion" : "マンション",
    "category" : "カテゴリー",
    "image_path" : "写真",
    "browse" : "参照",
    "delete" : "削除",
    "note" : "備考",
    "price" : "価格（税抜）",
    "image_path_list" : "画像",
    "tax" : "消費税額",
    "is_reduced_tax" : "軽減税率"
  },
  "small-category" : {
    "name_big": "大カテゴリ",
    "name_medium" : "中カテゴリ",
    "name" : "カテゴリ名",
    "note" : "説明",
  },
  "restaurants" : {
    "status_enabled" : "ステータス",
    "mansion" : "マンション",
    "name" : "名前",
    "name_table": "テーブル名",
    "store_id" : "店舗ID",
    "facility": "施設",
    "facility_name": "館内施設",
    "smaregi_store_id_list": "店舗ID 連携状態",
    "smaregi_store_id": "スマレジ店舗",
    "smaregi_table_id": "スマレジ店舗のテーブル",
    "note": "備考",
    "table_name": "テーブル名",
    "confirm": "店舗IDが既に入力されています。館内施設のIDで置き換えますか？",
    "title": "確認",
    "error_update": "選択している施設が存在しないため、別の施設を選択してください。"
  },
  "order_list" : {
    "status_delivered" : "ステータス",
    "mansion" : "マンション",
    "order_number" : "注文番号",
    "menu" : "メニュー",
    "customer_name" : "注文者名",
    "customer_phone" : "電話番号",
    "datetime_order" : "注文日時",
    "date_order" : "注文日",
    "hour_order" : "注文時間"
  },
  "answer" : {
    "name_big": "大カテゴリ",
    "name_medium" : "中カテゴリ",
    "name_small" : "小カテゴリ",
    "title": "名称",
    "note" : "説明",
    "contents": "回答の編集",
    "preview": "プレビュー"
  },
  "sale" : {
    "mansion" : "マンション",
    "prevMonth" : "先月",
    "nextMonth" : "翌月",
    "3month" : "売上比較（直近3ヶ月）",
    "byFacility" : "施設別",
    "prices" : "金額",
    "records" : "件数",
    "detail" : "表示",
    "detail2" : "詳細",
    "detail-label" : "内訳",
    "totalPrices" : "売上合計（¥）",
    "totalRecords" : "件数",
    "facility" : "施設",
    "price" : "売上",
    "percent" : "構成比",
    "detailPrice" : "売上詳細",
    "user_number" : "利用者番号",
    "customer_name" : "入居者名",
    "full_name" : "入居者名",
    "facility_usage_fee" : "入居者名",
    "association_usage_fee" : "組合利用料",
    "total_amount" : "合計金額",
    "status" : "ステータス",
    "purchase_type" : "施設",
    "customer_number" : "利用者番号",
    "amount_facility" : "施設利用料",
    "amount_association" : "組合利用料",
    "amount_total" : "合計金額",
    'no': 'No.',
    'purchased_at': '日付',
    'customer_id': '入居者ID',
    'amount': '金額',
    'download': 'CSVダウンロード',
  },
  "receipts" : {
    "datetime_order" : "注文日時",
    "order_id" : "注文番号",
    "customer_name" : "注文者名",
    "mansion": "マンション",
    "receipt_number": "領収書番号",
    "received_from": "宛名",
    "date_order" : "注文日",
    "hour_order" : "注文時間",
    "customer_order": "注文者名",
    "choose_list": "注文一覧から選択",
    "note": "但書",
    "customer_id": "顧客ID",
  },
  "csv_download" : {
    "gender_code": "性別",
    "register_date" : "登録期間",
    "receipt_date" : "請求期間",
    "name_kana_list_search": "名前・カナ",
    "csv_download_customer": 'csv_download_customer',
    "file_name_download_customer": 'list_customer.csv',
    "csv_download_receipts": 'csv_download_receipts',
    "file_name_download_receipts": 'list_receipt.csv',
    "download_customer_header": '会員情報CSV ダウンロード',
    "download_receipt_header": '請求情報CSV ダウンロード',
  },
  "notifies" : {
    "email": "メールアドレス",
    "apartment": "マンション",
    "add": "追加",
    "delete" : "削除",
    "add_new_field" : "入力欄の追加"
  },
  "announcements" : {
    "keyword" : "キーワード",
    "title" : "タイトル",
    "contents" : "本文",
    "created_at": "公開日",
    "published_at": "メール送信日",
    "display" : "表示",
    "mail": "メール送信",
    "confirm_title": "確認",
    "confirm_mail_first": "このお知らせをメール送信しますか？",
    "confirm_mail_again": "このお知らせは既に送信されています。もう一度メール送信しますか？",
  },
  "csv_upload" : {
    "file_select": "CSVファイル",
    "confirm_text": "OK",
    "confirm_modal_title": "ほのぼの会計の連携CSVをアップロードすると既存の会員情報が変更される可能性があります。\n" +
      "続行してもよろしいですか？",
    "confirm_modal_invoice_title": "請求書・領収書取り込みCSVをアップロードすると、指定されたアップロード月の請求書または領収書PDFが作成されます。\n" +
      "同年月でのPDFファイルが既に生成されている場合は上書きされます。続行してもよろしいですか？",
    "description_label": 'ほのぼの会計から出力した顧客情報をCSV形式で取り込みます。',
    "description_label_2": '既に利用者番号が登録されているデータはCSVの内容で更新され、それ以外のデータは新しく追加されます。',
    "description_label_3": '一度にアップロード可能な顧客情報は最大で1,000件（1,000行）となります。',
    "description_label_4": 'ほのぼの会計から出力した請求データの消し込み（決済が正常完了した請求）情報を取り込んで反映します。',
    "description_label_5": '既に反映が完了して「決済済み」となった請求情報は無視され、それ以外の情報は消し込み処理が行われます。',
    "description_label_6": '一度にアップロード可能な消し込み情報は最大で1,000件（1,000行）となります。',
    "upload_success": 'アップロードに成功しました。',
    "upload_error": 'アップロードに失敗しました。再度お試しください。',
    "upload_customer_header": "ほのぼの会計 顧客連携CSV アップロード",
    "upload_postcode_header": "郵便番号CSV アップロード",
    "upload_delete_invoice_header": "ほのぼの会計 請求情報の消し込み（決済完了）CSV アップロード",
    "postal_code_description_label": '日本郵便の以下ページからダウンロードしたCSVファイルを取り込みます。郵便番号データは上書きされます。',
    "postal_code_description_label_2": 'https://www.post.japanpost.jp/zipcode/dl/kogaki-zip.html',
    "postal_code_confirm_modal_title": "郵便番号CSVをアップロードすると既存の郵便番号情報が変更される可能性があります。\n" +
      "続行してもよろしいですか？",
    "delete_invoice_success": "消し込み処理が正常に完了しました。",
    "invoice_success": "請求書・領収書CSV取込処理を開始しました",
    "upload_file_incorrect_format": "正しい形式のCSVファイルをアップロードしてください。",
    "delete_invoice_confirm_modal_title": "ほのぼの会計 請求情報の消し込み（決済完了）CSV アップロードすると既存の請求情報の消し込みの情報が変更される可能性があります。\n" +
      "続行してもよろしいですか？",
    'mansion_apartment_denied': 'この操作を行う場合は権限をマンションの担当者にお問い合わせください。',
    'honobono_accountin_upload_header': 'ほのぼの会計 請求書・領収書取込CSV アップロード',
      'month_upload': 'アップロード月',
      'type': '種類',
      'file_detail': '明細付CSVファイル' ,
      'file_detail_99999': 'エボ利用明細付CSVファイル' ,
      'file_detail_88888': '管理組合利用明細付CSVファイル' ,
      'file_invoice': '消費税内訳付CSVファイル' ,
      "description_label_7": 'ほのぼの会計から出力した請求書・領収書の情報を取り込んで反映します。',
      "description_label_8": '取り込み処理が完了すると結果のメール通知が行われます。',
      "description_label_9": '一度にアップロード可能な請求書・領収書の情報は最大で1,000件（1,000行）となります。',
      "description_label_10": 'ほのぼの会計から出力した請求書・領収書の情報を取り込んで反映します。',
      "description_label_11": '一度にアップロード可能な請求書・領収書の情報は最大で1,000件（1,000行）となります。',
  },
  "invoice_receipt" : {
    "mansion_name": "マンション",
    "customer_name": "顧客",
    "month": "アップロード月",
    "type": "種類",
    "file_name": "ファイル",
    "office_code": "事業所番号",
  },

  // kyykhang
  "menu_student": {
    "list_title": "Danh sách nhà hàng",
    "name": "Tên tiếng Nhật",
    "name_vn": "Tên tiếng Việt",
    "sell_date": "Ngày bán",
    "price_s": "Giá size S",
    "price_m": "Giá size M",
    "price_l": "Giá size L",
    "image": "Ảnh",
    "description": "Mô tả tiếng Nhật",
    "description_vn": "Mô tả tiếng Việt"
  },
  "language": {
    'vn': "VN",
    'jp': "JP",
  },
  "common_vn": {
    "cancel": "Hủy",
    "confirm": "Xác nhận",
    "delete": "Xóa",
    "edit": "Chỉnh sửa",
    "create": "Tạo mới",
    "save": "Lưu",
    "delete confirm title": "Xác nhận",
    "delete confirm": "Bạn có chắc chắn muốn xóa không?",
    "action": "Hành động",
    "return": "Quay lại",
    "confirm": "Xác nhận",
    "detail": "Chi tiết",
    "money_unit": "VNĐ",
    "days_of_week": {
      1: "Thứ 2",
      2: "Thứ 3",
      3: "Thứ 4",
      4: "Thứ 5",
      5: "Thứ 6",
      6: "Thứ 7",
      7: "Chủ nhật"
    },
    "date_string": "{day_of_week} ngày {day} tháng {month} năm {year}",
    "update": "Cập nhật",
    "export": "Xuất CSV",
    "export_ok": "Xuất file thành công",
    "slug": "Slug",
    'meta_description': "Meta description",
    "save_ok": "Lưu thành công"
  },
  // restaurant
  "restaurant": {
    "list_title": "Danh sách nhà hàng",
    "name": "Tên nhà hàng",
    "address": "Địa chỉ tiếng Nhật",
    "address_vn": "Địa chỉ tiếng Việt",
    "hotline": "Hotline",
    "bank_number": "Số tài khoản",
    "bank_name": "Tên ngân hàng",
    "bank_account_name": "Tên chủ tài khoản",
    "logo": "Logo nhà hàng",
    "qr": "QR Code ngân hàng",
    "slug": "Slug",
  },
  // student_order
  "student_order": {
    "list_title": "Danh sách đặt cơm học sinh",
    "student_name": "Học sinh",
    "item_name": "Tên món ăn",
    "size": "Size",
    "price": "Giá tiền",
    "image_path": "Ảnh",
    "status": "Trạng thái đơn hàng",
    "paid_status": "Trạng thái thanh toán",
    "order_time": "Thời gian đặt",
    "order_code": "Mã đơn hàng",
    "order_date": "Ngày đặt hàng",
    "payment": "Phương thức thanh toán",
    "currency": "Đơn vị",
    "item_quantity": "Số lượng",
    "serve_date": "Ngày ăn",
    "menu_name": "Thực đơn",
    "order_modal": {
      "modal_title": "THÔNG TIN ĐƠN HÀNG",
      "code_title": "MÃ ĐƠN HÀNG: ",
      "label_order_date": "Ngày đặt hàng: ",
      "label_customer_name": "Người đặt hàng: ",
      "label_email": "Email: ",
      "label_phone": "Điện thoại liên lạc: ",
      "label_receiver": "Người nhận hàng: ",
      "label_class": "Lớp: ",
      "label_payment_method": "Hình thức thanh toán: ",
      "label_paid_status": "Tình trạng thanh toán: ",
      "button_add_menu": "Thêm món",
      "label_select_menu": "Chọn món"
    },
    "payment_options": {
      "transfer": "Chuyển khoản",
      "in_store": "Thanh toán tại cửa hàng",
      "at_school": "Thanh toán tại cửa trường",
    },
    "paid_status_options": {
      "unpaid": "Chưa thanh toán",
      "paid": "Đã thanh toán",
    },
    "btn_export_order_menus": "Bảng tổng hợp",
    "btn_export_order_labels": "In nắp hộp",
  },

  // category
  "category": {
    "list_title": "Danh sách danh mục",
    "name_other": "Tên danh mục",
    "name": "Tên danh mục tiếng Nhật",
    "name_vn": "Tên danh mục tiếng Việt",
    "image": "Ảnh",
  },
  // category
  "category_menu": {
    "list_title": "Danh sách menu",
    "name": "Menu tiếng Nhật",
    "name_vn": "Menu tiếng Việt",
    "category": "Category tiếng Nhật",
    "image": "Ảnh",
    "price": "Giá",
    "description": "Mô tả tiếng Nhật",
    "description_vn": "Mô tả tiếng Việt",

  },

  // student_invoice
  "student_invoice": {
    "year_month": "Tháng",
    "collection_date": "Ngày thu tiền",
    "customer_code": "Mã khách hàng",
    "email": "Email",
    "phone_number": "Số điện thoại",
    "status": "Trạng thái hoá đơn",
    "invoice": "Hoá đơn",
    "label_mail_subject": "Tiêu đề mail",
    "label_mail_content": "Nội dung mail",
    "button_send_invoices": "Gửi hoá đơn",
    "button_confirm_send": "Xác nhận gửi",
    "button_create_month_invoices": "Tạo hoá đơn tháng",
    "modal_title": "Xác nhận gửi hoá đơn",
    "send_ok": "Các hoá đơn đã được gửi thành công",
    "status_options": {
      "sent": "Đã gửi",
      "not_sent": "Chưa gửi"
    },
  },

  // table_order
  "table_order": {
    "list_title": "Danh sách đặt bàn",
    "customer_name": "Tên khách hàng",
    "order_time": "Thời gian đặt",
    "status": "Trạng thái",
    "phone_number": "Số điện thoại",
    "restaurant": "Nhà hàng",
    "guest_number": "Số lượng khách",
    "note": "Ghi chú",

    // status_order
    "status_order": {
      "waiting": "Chờ xác nhận",
      "confirmed": "Đã xác nhận",
      "cancelled": "Đã hủy"
    },
  },

  // standard_order
  "standard_order": {
    "list_title": "Danh sách đặt cơm học sinh",
    "price": "Tổng tiền",
    "status": "Trạng thái đơn hàng",
    "paid_status": "Trạng thái thanh toán",
    "order_time": "Thời gian đặt hàng",
    "payment": "Phương thức thanh toán",
    "currency": "Đơn vị",
    "item_quantity": "Số lượng",
    "menu_name": "Thực đơn",
    "restaurant_name": "Tên nhà hàng",
    "customer_name": "Tên khách hàng",
    "customer_phone": "Số điện thoại",
    "order_modal": {
      "modal_title": "THÔNG TIN ĐƠN HÀNG",
      "label_order_time": "Thời gian đặt hàng: ",
      "label_customer_name": "Họ tên: ",
      "label_customer_email": "Email: ",
      "label_customer_phone": "Điện thoại: ",
      "label_customer_address": "Địa chỉ: ",
      "label_customer_note": "Ghi chú: ",
      "label_payment_method": "Hình thức thanh toán: ",
      "label_status": "Trạng thái đơn hàng: ",
      "unit_price": "Đơn giá",
    },
    "payment_options": {
      "transfer": "Chuyển khoản ngân hàng",
      "cash": "Trả tiền mặt khi nhận hàng"
    },
    "status_options": {
      "completed": "Đã hoàn thành",
      "canceled": "Đã huỷ",
    },
    "btn_export_order_menus": "Bảng tổng hợp",
    "btn_export_order_labels": "In nắp hộp",
  },

  // news category
  "news_category": {
    "list_title": "Danh sách danh mục",
    'name': "Tên danh mục tiếng Nhật",
    'name_vn': "Tên danh mục tiếng Việt",
  },

  // news
  "news": {
    "list_title": "Danh sách tin tức",
    "category": "Danh mục",
    'title': "Tiêu đề tiếng Nhật",
    'title_vn': "Tiêu đề tiếng Việt",
    'content': "Nội dung tiếng Nhật",
    'preview': "Preview nội dung tiếng Nhật",
    'content_vn': "Nội dung tiếng Việt",
    'preview_vn': "Preview nội dung tiếng Việt",
    'thumbnail': "Ảnh thumbnail"
  },

  // kyy_customers
  "kyy_customers": {
    "list_title": "Danh sách khách hàng",
    "user_name": "Mã đăng nhập",
    "email": "Email",
    "password": "Mật khẩu",
    "phone_number": "Số điện thoại",
    "class": "Lớp",
    "phone_number": "Số điện thoại",
    "customer_name": "Tên khách hàng",
    "student_name": "Tên học sinh",
    "student_class": "Lớp",
    "status": "Trạng thái",
    "status_options": {
      "active": "Hoạt động",
      "inactive": "Không hoạt động"
    },
  },

  // home setting
  "home_setting": {
    "banner_path": "Ảnh banner",
    "introduction_title": "Tiêu đề giới thiệu tiếng Nhật",
    "introduction_title_vn": "Tiêu đề giới thiệu tiếng Việt",
    "introduction_path": "Ảnh giới thiệu",
    "introduction": "Nội dung giới thiệu tiếng Nhật",
    "introduction_vn": "Nội dung giới thiệu tiếng Việt",
    "gallery_text": "Tiêu đề gallery tiếng Nhật",
    "gallery_text_vn": "Tiêu đề gallery tiếng Việt",
    "gallery": "Danh sách ảnh trưng bày",
    "news_list": "Danh sách tin tức hiển thị",
    "confirm_update_setting": "Bạn có chắc chắn muốn cập nhật cài đặt trang chủ không?",
  },

  // web setting
  "web_setting": {
    "home_title": "Tiêu đề trang chủ tiếng Nhật",
    "home_title_vn": "Tiêu đề trang chủ tiếng Việt",
    "home_description": "Mô tả trang chủ tiếng Nhật",
    "home_description_vn": "Mô tả trang chủ tiếng Việt",
    "hotline": "Hotline",
    "logo_path": "Logo",
    "favicon_path": "Favicon",
    "link_fb": "Link Facebook",
    "link_zalo": "Link Zalo",
    "link_youtube": "Link Youtube",
    "link_insta": "Link Instagram",
    "link_tiktok": "Link Tiktok",
    "link_ggmap": "Link Google Maps",
    "email": "Email",
    "brand_name": "Tên thương hiệu",
    "company_name": "Tên công ty",
    "company_address": "Địa chỉ công ty tiếng Nhật",
    "company_address_vn": "Địa chỉ công ty tiếng Việt",
    "company_tax_id": "Mã số thuế",
    "confirm_update_setting": "Bạn có chắc chắn muốn cập nhật cài đặt chung không?",
  },
}
